.error_warning::selection {
  background-color: rgba(232, 63, 111, 0.8);
  color: white;
}

.loading_icon {
  margin: auto auto;
}

.pointer {
  color: red;
  font-size: large;
}

.pointer_start {
  color: red;
  font-size: large;
  margin-top: -17px;
}

.position {
  font-size: small;
}

.error_box {
  background-color: white;
  padding: 4px;
  border-radius: 6px;
}

.editor {
  height: 73vh;
  padding: 10px;
  background-color: white;
  box-sizing: border-box;
  border-radius: 9px;
  margin-top: 6px;
}

.post {
  background-color: #d46b08!important;
}

.switch_space {
  margin-right: 10px;
}
